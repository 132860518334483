










import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "backTag",
})
export default class backTag extends Vue {
  @Prop({
    required: true
  })
  title: string = "";

}
